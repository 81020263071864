// Module
var code = "<template id=\"template-data-types\"> <div class=\"row\"> <div class=\"sgx-fpds-consent-logo-container\"> <a class=\"sgx-fpds-consent-logo\"></a> </div> </div> <div class=\"row\"> <div class=\"sgx-fpds-consent-content\"> <style>.sgx-data-types{line-height:1.5rem}.sgx-data-types ol{margin-top:1rem;padding-left:2rem;list-style-type:circle}.sgx-data-types>ol li{margin-bottom:1rem}</style> <div class=\"sgx-data-types\"> <h2 class=\"sgx-base-text-h2\">Direct holding information from CDP:</h2> <ol class=\"sgx-data-types-list\"> <li>Shares</li> <li>REITs</li> <li>ETFs</li> <li>Depository Receipts</li> <li>Bonds (incl. SGS and SSBs)</li> <li>Structured Products (e.g. DLCs, Warrants)</li> <li>Any other direct holdings with CDP (i.e. excluding SRS and CPFIS)</li> </ol> </div> </div> </div> </template> ";
// Exports

  export default (function() {
    return function parseDOMString(tagString) {

  function prepare () {
    let range = document.createRange();
    let node = range.createContextualFragment(tagString);
    let firstChild = node.firstElementChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(code);
  })()