/**
 * @module auth-service
 * @desc authentication provider for login and transaction signing.
 */
import ConfigService from 'sgx-config-service';
import BaseService from 'services/base-service';
import { loginErrorMessage } from '../utils/common-utils';
import { FetchUtils, URLUtils } from 'sgx-base-code';
import {
  getLoginSource,
  setAuthenticated,
  getClientId,
  getSignature
} from '../utils/storage-utils';

let instance = null;

class AppLoginService extends BaseService {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  get endpoints() {
    return {
      singpassLogin: ConfigService.endpoints.AUTH_SINGPASS_SUBMIT,
      singpassSession: ConfigService.endpoints.AUTH_SINGPASS_SESSION,
      sgxConsent: ConfigService.endpoints.AUTH_SGX_CONSENT,
      sgxCancelAuthorization: ConfigService.endpoints.AUTH_SGX_CANCEL
    };
  }

  // eslint-disable-next-line consistent-return
  registerSgFinDexConsent({authToken, clientId, signature}) {
    if (!authToken || !clientId || !signature) {
      return Promise.reject(`No authToken (${authToken}) or clientId (${clientId}) or signature (${signature}) provided`);
    }
    const params = {
      'client_id': clientId,
      'Signature': signature,
      'token': authToken
    };
    const url = URLUtils.setQueryParams(this.endpoints.sgxConsent, params, false);
    window.location.href = url;
  }

  // eslint-disable-next-line consistent-return
  cancelSgFinDexConsent({authToken, clientId, signature}) {
    if (!clientId || !signature) {
      return Promise.reject(`No clientId (${clientId}) or signature (${signature}) provided`);
    }
    const params = {
      'client_id': clientId,
      'Signature': signature,
      'token': authToken
    };
    const url = URLUtils.setQueryParams(this.endpoints.sgxCancelAuthorization, params, false);
    window.location.href = url;
  }

  /**
   * Send token from SingPass to our endpoint to start a session.
   * @param {String} authorizationCode token returned from SingPass
   * @param {String} state state used in SingPass initialization
   * @param {String} redirectUri redirect uri used in SingPass initialization
   * @param {Object} headers additional request headers
   * @param {Function} errCallback pass this callback to called on click of button in the status-indicator
   * @returns {void}
   */
  loginWithSingPass({ authorizationCode = '', state = '', redirectUri = '', headers = {}, errCallback }) {
    const body = JSON.stringify({
      singpassCode: authorizationCode,
      singpassRedirectUri: redirectUri,
      singpassState: state,
      loginSource: getLoginSource()
    });
    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.singpassLogin, {
        method: 'POST',
        headers: {
          ...headers,
          ...this.HEADERS
        },
        body
      })
        .then(response => {
          if (response.ok) {
            setAuthenticated(true);
            return resolve();
          }
          return reject('sgx-login.error.default');
        })
        .catch(error => {
          const status = error.status;
          reject({message: loginErrorMessage({method: 'loginWithSingPass', status}), callback: errCallback});
        });
    });
  }

  /**
   * Initiate an anonymous session to get state and nonce.
   * @param {String} callbackURI 
   * @returns {void}
   */
  getSingpassSession(callbackURI) {
    return new Promise((resolve, reject) => {
      var queryParams = URLUtils.getQueryParams(window.location.search);
      if (!queryParams[ConfigService.queryParams.clientId] || !queryParams[ConfigService.queryParams.signature]) {
        const clientId = getClientId();
        const signature = getSignature();
        if (!clientId || !signature) {
          return reject(loginErrorMessage({ method: 'loginWithSingPass', status: 400 }));
        }
        queryParams[ConfigService.queryParams.clientId] = clientId;
        queryParams[ConfigService.queryParams.signature] = signature;
      }
      queryParams['redirectUri'] = callbackURI;
      const url = URLUtils.setQueryParams(this.endpoints.singpassSession, queryParams, false);
      return FetchUtils.fetch(url, {
        method: 'POST',
        headers: this.HEADERS,
      })
        .then(response => {
          if (response.status >= 400 && response.status <= 499) {
            return reject(loginErrorMessage({method: 'loginWithSingPass', status: response.status}));
          }
          const authToken = response.headers.get(ConfigService.request.headers.authToken);
          return response.json()
            .then(responseBody => {
              if (responseBody.meta && responseBody.meta.respCode && responseBody.meta.respCode === '1001') {
                return reject(loginErrorMessage({method: 'loginWithSingPass', status: 503}));
              }
              const data = responseBody.data || {};
              const { singpassState: state, singpassNonce: nonce, redirectUri: redirectUri } = data;
              return resolve({ authToken, state, nonce, redirectUri });
            });
        })
        .catch(errMessage => {
          if (errMessage) {
            if (typeof errMessage === 'object' && errMessage.status) {
              return reject(loginErrorMessage({ method: 'loginWithSingPass', status: errMessage.status }));
            } else if (typeof errMessage === 'string') {
              return reject(errMessage);
            }
          }
          return reject(loginErrorMessage({ method: 'loginWithSingPass', status: 500 }));
        });
    });
  }
}

export default new AppLoginService();
