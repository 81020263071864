// Module
var code = "<template id=\"template-service-coming-soon-page\"> <div class=\"row\"> <div class=\"sgx-fpds-consent-logo-container\"> <a class=\"sgx-fpds-consent-logo\"></a> </div> </div> <div class=\"row\"> <sgx-status-indicator status=\"informational\" data-i18n=\"[title]app.service-coming-soon.status-title\" class=\"sgx-fpds-service-coming-soon-content\"></sgx-status-indicator> </div> </template> ";
// Exports

  export default (function() {
    return function parseDOMString(tagString) {

  function prepare () {
    let range = document.createRange();
    let node = range.createContextualFragment(tagString);
    let firstChild = node.firstElementChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(code);
  })()